import { render, staticRenderFns } from "./WatcherSearch.vue?vue&type=template&id=6641472a&scoped=true&"
import script from "./WatcherSearch.vue?vue&type=script&lang=js&"
export * from "./WatcherSearch.vue?vue&type=script&lang=js&"
import style0 from "./WatcherSearch.vue?vue&type=style&index=0&id=6641472a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6641472a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VPagination } from 'vuetify/lib/components/VPagination';
installComponents(component, {VCard,VCardText,VCardTitle,VChip,VChipGroup,VDivider,VList,VListItem,VListItemContent,VListItemGroup,VListItemSubtitle,VListItemTitle,VPagination})
