<template>
  <v-card>
    <v-card-title>
      {{ $t('search.results') }}
      <span
        v-if="searchResults.length > 0"
        class="pl-2"
      >
        ({{ searchResultStart }} - {{ searchResultEnd }}) of {{ searchResults.length }}
      </span>
    </v-card-title>
    <v-card-text
      class="pb-0"
    >
      <v-divider />
      <v-chip-group
        v-model="selectedStateIds"
        multiple
        active-class="primary--text"
      >
        <v-chip
          v-for="tag in watcherStates"
          :key="tag"
          :class="tag"
        >
          {{ tag }}
        </v-chip>
      </v-chip-group>
      <v-divider />
    </v-card-text>
    <v-card-text v-if="searchResults.length === 0 && (!loading || !selectedStateIds.length)">
      <strong>{{ $t('notification.noProductsFound') }}</strong>
    </v-card-text>
    <v-card-text v-else-if="searchResults.length === 0">
      <strong>{{ $t('notification.loading') }}</strong>
    </v-card-text>
    <v-card-text
      v-else
      class="pt-0"
    >
      <v-pagination
        v-model="searchPage"
        :length="searchMaxPages"
        :total-visible="5"
      ></v-pagination>
      <v-divider />
      <v-list dense>
        <v-list-item-group
          ref="watchers"
          color="primary"
          @change="onWatcherChange"
        >
          <v-list-item
            v-for="(watcher, i) in visibleSearchResults"
            :key="i"
          >
            <v-list-item-content @click="onWatcherSelection(watcher, i)">
              <v-list-item-title v-text="getWatcherTitle(watcher)" />
              <v-list-item-subtitle v-text="watcher.dq" />
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: 'WatcherSearch',
    props: {
      watchers: {
        type: Array,
        required: true,
      },
      query: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        searchPage: 1,
        searchResultsPerPage: 5,

        selectedWatcherPosition: undefined,
        selectedWatcherId: null,

        loading: true,

        watcherStates: ["ACTIVE", "UNKNOWN", "INVALID"],
        selectedStateIds: [0, 1, 2],
        selectedStateNames: ["ACTIVE", "UNKNOWN", "INVALID"],
      }
    },
    computed: {
      searchResults() {
        let queryUpper = this.query == null ? '' : this.query.toUpperCase()

        return this
          .watchers
          .filter(watcher => this.selectedStateNames.includes(watcher.productState))
          .filter(watcher => {
            return queryUpper === ''
              || watcher.dq.toUpperCase().includes(queryUpper)
              || watcher.id.toUpperCase().includes(queryUpper)
              || watcher.info.toUpperCase().includes(queryUpper)
              || (watcher.derived != null && watcher.derived.name != null && watcher.derived.name.toUpperCase().includes(queryUpper))
              || (watcher.derived != null && watcher.derived.details != null && watcher.derived.details.toUpperCase().includes(queryUpper))
          })
      },
      visibleSearchResults() {
        return this.searchResults.slice(this.searchResultStart - 1, this.searchResultEnd)
      },
      searchMaxPages() {
        return Math.max(1, Math.ceil(this.searchResults.length / this.searchResultsPerPage))
      },
      searchResultStart() {
        return (this.searchPage - 1) * this.searchResultsPerPage + 1
      },
      searchResultEnd() {
        return Math.min(this.searchResults.length, this.searchPage * this.searchResultsPerPage)
      },
    },
    watch: {
      searchResults(newValue) {
        if(this.query != null && this.query.length > 0) {
          this.$set(this, 'searchPage', 1)
        }
        
        this.$nextTick(() => {
          if(newValue.length > 0 && this.$refs['watchers'] != null) {
            let pos = this.visibleSearchResults.findIndex(watcher => watcher.id === this.selectedWatcherId)

            this.selectedWatcherPosition = pos >= 0 ? pos : undefined
            this.$set(this.$refs.watchers, 'internalLazyValue', this.selectedWatcherPosition)
          }
        })
      },
      searchPage() {
        let pos = this.visibleSearchResults.findIndex(watcher => watcher.id === this.selectedWatcherId)

        this.selectedWatcherPosition = pos >= 0 ? pos : undefined
        this.$set(this.$refs.watchers, 'internalLazyValue', this.selectedWatcherPosition)
      },
      watchers() {
        this.loading = false
      },
      selectedStateIds() {
        this.selectedStateNames = this.selectedStateIds.map(i => this.watcherStates[i])
      },
    },
    methods: {
      onWatcherSelection(watcher, position) {
        this.selectedWatcherPosition = position
        this.selectedWatcherId = watcher != null ? watcher.id : null
        this.$emit('onWatcherSelection', watcher)
      },
      onWatcherChange(position) {
        if (position === undefined) this.$set(this.$refs.watchers, 'internalLazyValue', this.selectedWatcherPosition)
      },
      getWatcherTitle(watcher) {
        return `${watcher.derived.name} (${watcher.derived.details})`
      },
      update(watcherId) {
        if(watcherId == null) {
          this.$nextTick(() => {
            if(this.searchPage > this.searchMaxPages) {
              this.$set(this, 'searchPage', this.searchPage - 1)
            }
            this.$set(this.$refs['watchers'], 'internalLazyValue', undefined)
          })
        } else {
          this.$emit('resetSearch')
          this.$nextTick(() => {
            let position = this.watchers.findIndex(watcher => watcher.id === watcherId)
            this.$set(this, 'selectedWatcherId', watcherId)
            this.$set(this, 'searchPage', Math.floor(position / this.searchResultsPerPage) + 1)

            this.$nextTick(() => {
              this.$set(this.$refs['watchers'], 'internalLazyValue', position % this.searchResultsPerPage)
              this.$refs['watchers'].$forceUpdate()
            })
          })
        }
      },
    },
  }
</script>

<style scoped>
  .v-chip.v-chip--active.ACTIVE {
    color: var(--v-success-base) !important;
  }
  .v-chip.v-chip--active.UNKNOWN {
    color: var(--v-warning-base) !important
  }
  .v-chip.v-chip--active.INVALID {
    color: var(--v-error-base) !important;
  }
  .v-chip.v-chip--active {
    font-weight: bold;
  }
</style>
