<template>
  <v-card>
    <v-card-title>
      <h2>{{ $t('actions.createX', [$t('watcher.watcher')]) }}</h2>
      <CloseButton @click="cancel"></CloseButton>
    </v-card-title>
    <v-divider class="mt-0"></v-divider>
    <v-card-text>
      <watcher-editor
        v-model="watcher"
        :is-insert="true"
      ></watcher-editor>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        large
        color="primary"
        @click="create"
      >
        <v-icon
          left
        >
          check
        </v-icon>
        {{ $t('actions.create') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import CloseButton from "@/components/stocks/CloseButton"
  import WatcherEditor from "@/components/watch/WatcherEditor"

  export default {
    name: "CreateWatcherDialog",
    components: {WatcherEditor, CloseButton},
    data() {
      return {
        watcher: {
          dq: '',
          instrumentType: 'DERIVATE',
          ignoreUntil: null,
          validUntil: null,
          info: '',
          additionalChecks: [],
        },
      }
    },
    methods: {
      create() {
        if (!this.watcher.dq.match(/^(QP00|QU)[A-Z]{2}[A-Z0-9]*[0-9]$/) || this.watcher.validUntil == null) {
          this.$store.commit('SHOW_SNACKBAR', { text: this.$t('watcher.invalidInput') })
        } else {
          this.$emit('onWatcherCreation', this.watcher)
          this.cancel()
        }
      },

      cancel() {
        this.resetInputs()
        this.$emit('cancel')
      },
      resetInputs() {
        this.watcher = {
          dq: '',
          instrumentType: 'DERIVATE',
          ignoreUntil: null,
          validUntil: null,
          info: '',
          additionalChecks: [],
        }
      },
    },
  }
</script>

<style scoped>

</style>