<template>
  <v-container
    fluid
    grid-list-xl
  >
    <toolbar
      :title="$t('watcher.watchers')"
      :search.sync="search"
    >
      <template v-slot:actions>
        <v-col class="shrink">
          <v-btn
            style="height:48px;"
            @click="showAddWatcherDialog"
          >
            <v-icon
              color="accent"
              left
            >
              add_circle_outline
            </v-icon>
            {{ $t('actions.createX', [$t('watcher.watcher')]) }}
          </v-btn>
        </v-col>
        <v-col class="shrink">
          <v-btn
            v-if="selectedWatcher"
            large
            color="primary"
            @click="saveWatcher"
          >
            <v-icon
              left
            >
              save
            </v-icon>
            {{ $t('actions.save') }}
          </v-btn>
        </v-col>
        <v-col class="shrink">
          <v-btn
            v-if="selectedWatcher"
            large
            @click="updateWatcherStatus"
          >
            <v-icon
              color="accent"
              left
            >
              sync
            </v-icon>
            {{ $t('actions.update') }}
          </v-btn>
        </v-col>
        <v-col class="shrink">
          <v-btn
            v-if="selectedWatcher"
            large
            @click="resetChanges"
          >
            <v-icon
              color="accent"
              left
            >
              undo
            </v-icon>
            {{ $t('actions.reset') }}
          </v-btn>
        </v-col>
        <v-col class="shrink">
          <v-btn
            v-if="selectedWatcher"
            large
            @click="showConfirmDeleteDialog"
          >
            <v-icon
              color="error"
              left
            >
              delete_forever
            </v-icon>
            {{ $t('actions.delete') }}
          </v-btn>
        </v-col>
      </template>
    </toolbar>

    <v-row>
      <v-col
        xs="12"
        sm="6"
        md="4"
        lg="3"
        xl="3"
      >
        <watcher-search
          ref="search"
          :watchers="watchers"
          :query="search"
          @onWatcherSelection="onWatcherSelection"
          @resetSearch="search = ''"
        />
      </v-col>
      <v-col
        v-if="selectedWatcher"
        xs="12"
        sm="6"
        md="8"
        lg="7"
        xl="6"
      >
        <v-card>
          <v-card-text>
            <watcher-editor
              v-model="newSelectedWatcher"
              :is-insert="false"
            ></watcher-editor>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="createWatcherDialog"
      width="800"
      persistent
    >
      <create-watcher-dialog
        @cancel="createWatcherDialog = false"
        @onWatcherCreation="onWatcherCreation"
      />
    </v-dialog>

    <v-dialog
      v-if="selectedWatcher"
      v-model="deleteWatcherDialog"
      width="700"
    >
      <confirm-delete-watcher-dialog
        v-model="selectedWatcher.id"
        @cancel="deleteWatcherDialog = false"
        @confirmDelete="onWatcherDeleted"
      ></confirm-delete-watcher-dialog>
    </v-dialog>
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex'
  import WatcherEditor from "@/components/watch/WatcherEditor"
  import WatcherSearch from "@/components/watch/WatcherSearch"
  import Toolbar from "@/components/common/Toolbar"
  import CreateWatcherDialog from "@/components/watch/CreateWatcherDialog"
  import ConfirmDeleteWatcherDialog from "@/components/watch/ConfirmDeleteWatcherDialog"

  export default {
    name: "Watch",
    components: {ConfirmDeleteWatcherDialog, CreateWatcherDialog, Toolbar, WatcherSearch, WatcherEditor },
    data() {
      return {
        watchers: [],
        search: '',
        selectedWatcher: null,
        newSelectedWatcher: null,

        createWatcherDialog: false,
        deleteWatcherDialog: false,
      }
    },
    watch: {
      selectedWatcher(newValue) {
        this.$set(this, 'newSelectedWatcher', (newValue == null) ? null : this.cloneObject(newValue))
      },
    },
    mounted() {
      this.getAllActiveWatchers()
    },
    methods: {
      ...mapActions(['listActiveWatchers', 'updateWatcher', 'addWatcher', 'deleteWatcher']),
      getAllActiveWatchers() {
        let promise = this.listActiveWatchers()
        promise.then(response => {
          if (response.data != null && response.data.data != null && response.data.data instanceof Array) {
            this.watchers = response.data.data
          }
        })
        return promise
      },
      getWatcherTitle(watcher) {
        return `${watcher.derived.name} (${watcher.derived.details})`
      },
      onWatcherSelection(watcher) {
        this.selectedWatcher = watcher
      },
      showAddWatcherDialog() {
        this.createWatcherDialog = true
      },
      showConfirmDeleteDialog() {
        this.deleteWatcherDialog = true
      },
      saveWatcher() {
        if (this.newSelectedWatcher.dq != null && !!this.newSelectedWatcher.dq.match(/^(QP00|QU)[A-Z]{2}[A-Z0-9]*[0-9]$/)
          || this.newSelectedWatcher.validUntil != null && this.newSelectedWatcher.ignoreUntil != null
        ) {
          this.addWatcher(this.newSelectedWatcher).then(response => {
            if (response.data != null && response.data.data != null && response.data.data instanceof Object) {
              this.$store.commit('SHOW_SNACKBAR', {text: this.$t('watcher.successfulUpdate')})
              let position = this.watchers.findIndex(watcher => watcher.id === this.selectedWatcher.id)
              this.$set(this.watchers, position, response.data.data)
              this.$set(this, 'selectedWatcher', response.data.data)
              this.$refs['search'].update(this.selectedWatcher.id)
            } else {
              this.$store.commit('SHOW_SNACKBAR', {text: this.$t('watcher.errorOnUpdate')})
            }
          }).catch(() => {
            this.$store.commit('SHOW_SNACKBAR', { text: this.$t('watcher.errorOnUpdate') })
          })
        } else {
          this.$store.commit('SHOW_SNACKBAR', {text: this.$t('watcher.invalidInput')})
        }
      },
      resetChanges() {
        this.selectedWatcher = this.cloneObject(this.selectedWatcher)
      },
      updateWatcherStatus() {
        this.updateWatcher(this.selectedWatcher.id).then(response => {
          if (response.data != null && response.data.data != null && response.data.data instanceof Object) {
            this.$store.commit('SHOW_SNACKBAR', {text: this.$t('watcher.successfulUpdate')})
            let position = this.watchers.findIndex(watcher => watcher.id === this.selectedWatcher.id)
            this.$set(this.watchers, position, response.data.data)
            this.$set(this, 'selectedWatcher', response.data.data)
            this.$refs['search'].update(this.selectedWatcher.id)
          } else {
            this.$store.commit('SHOW_SNACKBAR', {text: this.$t('watcher.errorOnUpdate')})
          }
        }).catch(() => {
          this.$store.commit('SHOW_SNACKBAR', { text: this.$t('watcher.errorOnUpdate') })
        })
      },
      onWatcherCreation(watcher) {
        if(watcher != null) {
          this.addWatcher(watcher).then(response => {
            if(response.data != null && response.data.data != null && response.data.data instanceof Object) {
              this.$store.commit('SHOW_SNACKBAR', { text: this.$t('watcher.successfulCreation') })

              this.getAllActiveWatchers().then(() => {
                this.$refs['search'].update(response.data.data.id)
                this.$set(this, 'selectedWatcher', response.data.data)
              })
            } else {
              this.$store.commit('SHOW_SNACKBAR', { text: this.$t('watcher.errorOnCreation')})
            }
          }).catch(() => {
            this.$store.commit('SHOW_SNACKBAR', { text: this.$t('watcher.errorOnCreation')})
          })
        }
      },
      onWatcherDeleted(value) {
        this.deleteWatcher(value).then(() => {
          this.$store.commit('SHOW_SNACKBAR', {text: this.$t('watcher.successfulDelete')})
          this.getAllActiveWatchers().then(() => {
            this.$set(this, 'selectedWatcher', null)
            if(this.$refs['search'] != null) this.$refs['search'].update(null)
          })
        }).catch(() => {
          this.$store.commit('SHOW_SNACKBAR', {text: this.$t('watcher.errorOnDelete')})
        });
      },
      cloneObject(obj) {
        return JSON.parse(JSON.stringify(obj))
      },
    },
  }
</script>

<style scoped>

</style>
