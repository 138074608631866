<template>
  <v-container>
    <v-row>
      <v-col
        v-if="!isInsert"
        cols="12"
      >
        <div>
          <v-icon
            left
            :color="getStateInfoStyle(newValue.productState)"
            style="top: -1px;"
          >
            {{ getStateInfoIcon(newValue.productState) }}
          </v-icon>
          <strong :class="`${getStateInfoStyle(newValue.productState)}--text`">
            {{ $t('watcher.productStateInfo', [newValue.productState, formatLatestCheck(newValue.latestCheck)]) }}
          </strong>
        </div>
      </v-col>
      <v-col
        v-if="!isInsert"
        cols="12"
      >
        <v-text-field
          v-model="newValue.id"
          label="Watcher ID"
          readonly
          disabled
          @input="emitChange"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="newValue.dq"
          label="DQ"
          @input="emitChange"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="newValue.instrumentType"
          :items="instrumentTypes"
          :label="$t('watcher.instrumentType')"
          @input="emitChange"
        ></v-select>
      </v-col>
      <v-col cols="6">
        <v-dialog
          ref="dialog"
          v-model="validDateDialog"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="newValue.validUntil"
              v-bind="attrs"
              :label="$t('watcher.validUntil')"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="newValue.validUntil"
            scrollable
            @change="closeDatepicker"
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="validDateDialog = false"
            >
              Cancel
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col cols="6">
        <v-dialog
          ref="dialog"
          v-model="ignoreDateDialog"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="newValue.ignoreUntil"
              v-bind="attrs"
              :label="$t('watcher.ignoreUntil')"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="newValue.ignoreUntil"
            scrollable
            @change="closeDatepicker"
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="ignoreDateDialog = false"
            >
              Cancel
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="newValue.info"
          :label="$t('watcher.productWatcherInformation')"
          @input="emitChange"
        ></v-text-field>
      </v-col>
      <v-col
        v-for="(description, i) in $t('watcher.modeDescriptions')"
        :key="i"
        cols="6"
      >
        <v-checkbox
          v-model="newValue.additionalChecks"
          :value="modeFields[i]"
          :label="description"
          @change="emitChange"
        >
        </v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-divider />
      </v-col>
    </v-row>
    <v-row v-if="!isInsert">
      <v-col
        v-if="newValue.productMessage"
        cols="12"
      >
        <v-text-field
          :value="newValue.productMessage"
          readonly
          disabled
          :label="$t('watcher.productMessage')"
          @input="emitChange"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          :value="newValue.derived.wkn"
          label="WKN"
          readonly
          disabled
          @input="emitChange"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          :value="newValue.derived.isin"
          label="ISIN"
          readonly
          disabled
          @input="emitChange"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          :value="newValue.derived.name"
          :label="$t('watcher.productType')"
          readonly
          disabled
          @input="emitChange"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="newValue.derived.details"
          label="Details"
          readonly
          disabled
          @input="emitChange"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: "WatcherEditor",
    props: {
      value: {
        type: Object,
        required: true,
      },
      isInsert: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {
        instrumentTypes: ['DERIVATE', 'BOND', 'FUND', 'STOCK', 'INDEX'],
        dateFields: ['ignoreUntil', 'validUntil'],
        validDateDialog: false,
        ignoreDateDialog: false,
        modeFields: ['FUND_EQUIVALENCE_DATA', 'DERIVATE_NO_UNKNOWN_TYPE'],
      }
    },
    computed: {
      newValue: {
        get() {
          let clone = this.cloneObject(this.value)

          this.dateFields.forEach(field => {
            if (!isNaN(Date.parse(clone[field]))) {
              clone[field] = clone[field].substr(0, 10)
            }
          });

          if (!isNaN(Date.parse(clone.latestCheck))) {
            clone.latestCheck = clone.latestCheck.substr(0, 19).replace('T', ' ')
          }

          return clone
        },
        set(val) {
          this.$emit('input', val)
        },
      },
    },
    methods: {
      cloneObject(val) {
        return JSON.parse(JSON.stringify(val))
      },
      emitChange() {
        this.$emit('input', this.newValue)
      },
      closeDatepicker() {
        this.emitChange()
        this.ignoreDateDialog = false
        this.validDateDialog = false
      },
      getStateInfoStyle(state) {
        switch(state) {
        case 'ACTIVE': return 'success';
        case 'INVALID': return 'error';
        default: return 'warning';
        }
      },
      getStateInfoIcon(state) {
        switch(state) {
        case 'ACTIVE': return 'check_circle_outline';
        case 'INVALID': return 'error_outline';
        default: return 'help_outline';
        }
      },
      formatLatestCheck(latestCheck) {
        return latestCheck == null ? ' -- ': latestCheck
      },
    },
  }
</script>

<style scoped>

</style>
